import * as React from 'react';
import heroImageMobileUrl from '../../images/hero420.jpg';
import heroImageTabletUrl from '../../images/hero737.jpg';
import heroImageDesktopUrl from '../../images/hero1360.jpg';
import { hero } from './Hero.module.scss';

const Hero: React.FC = () => {
  return (
    <div className={hero}>
      <picture>
        <source media="(max-width: 420px)" srcSet={heroImageMobileUrl} />
        <source media="(min-width: 421px) and (max-width: 736px)" srcSet={heroImageTabletUrl} />
        <source media="(min-width: 737px)" srcSet={heroImageDesktopUrl} />
        <img src={heroImageDesktopUrl} alt="Zamów kartę upominkową DUKA" />
      </picture>
    </div>
  );
};

export default Hero;
