import cls from 'classnames';
import * as React from 'react';
import { flex, flexItem, debug as debugClass } from './Flex.module.scss';

const FlexContainer: React.FC<FlexContainerProps> = ({ debug, center, mt, mb, children }) => {
  const gap = { gap: '5px' };
  const margins = {
    marginTop: mt && mt + 'px',
    marginBottom: mb && mb + 'px',
  };
  const justifyContent = center
    ? {
        justifyContent: 'center',
      }
    : {};

  return (
    <div className={cls(flex, { [debugClass]: debug })} style={{ ...gap, ...margins, ...justifyContent }}>
      {children}
    </div>
  );
};

const FlexItem: React.FC<Pick<FlexProps, 'width'>> = ({ width, children }) => {
  return (
    <div className={flexItem} style={{ width, maxWidth: width, flexBasis: width }}>
      {children}
    </div>
  );
};

const Flex: React.FC<FlexProps> = ({ item, width, mt, mb, debug, center, children }) => {
  return item ? (
    <FlexItem width={width}>{children}</FlexItem>
  ) : (
    <FlexContainer mt={mt} mb={mb} debug={debug} center={center}>
      {children}
    </FlexContainer>
  );
};

export default Flex;
