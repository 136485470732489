import cls from 'classnames';
import React from 'react';
import Slider from 'react-slick';
import { RiCheckboxCircleLine } from '@react-icons/all-files/ri/RiCheckboxCircleLine';
import * as styles from './CardsCarouselModal.module.scss';
import { CardCarouselModalNextArrow, CardCarouselModalPrevArrow } from './CardsCarouselModalArrows';
import './slick-theme.scss';
import './slick.scss';

const CardsCarouselModal: React.FC<CardsCarouselModalProps> = ({ slides, initialSlide, onClick, onChange }) => {
  const handleClick = (item: CardType) => {
    if (typeof onClick === 'function') {
      onClick(item);
    }
  };

  const handleChange = index => {
    if (typeof onChange === 'function') {
      onChange(slides[index]);
    }
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: slides.length > 3,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: handleChange,
    initialSlide: initialSlide,
    nextArrow: <CardCarouselModalNextArrow />,
    prevArrow: <CardCarouselModalPrevArrow />,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.cardsCarousel}>
      <Slider {...settings}>
        {slides.map(item => {
          return (
            <div key={item.id} className={styles.cardsCarouselSlide} onClick={() => handleClick(item)}>
              <img src={item.imageFull} />
              {item.selected && (
                <div className={cls(styles.cardsCarouselSelected, styles.cardsCarouselSelectedWhite)}>
                  <RiCheckboxCircleLine />
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CardsCarouselModal;
