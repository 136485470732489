import formurlencoded from 'form-urlencoded';
import { useFormik } from 'formik';
import { PageProps } from 'gatsby';
import produce from 'immer';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle';
import AppWrapper from '../components/AppWrapper/AppWrapper';
import Background from '../components/Background/Background';
import Button from '../components/Button/Button';
import CardModal from '../components/CardModal/CardModal';
import CardCarousel from '../components/CardsCarousel/CardsCarousel';
import Checkbox from '../components/Checkbox/Checkbox';
import Container from '../components/Container/Container';
import Error from '../components/Error/Error';
import Flex from '../components/Flex/Flex';
import Hero from '../components/Hero/Hero';
import Input from '../components/Input/Input';
import LoaderIcon from '../components/LoaderIcon/LoaderIcon';
import OrderName from '../components/OrderName/OrderName';
import OrderPrice from '../components/OrderPrice/OrderPrice';
import PriceSlider from '../components/PriceSlider/PriceSlider';
import RowLabel from '../components/RowLabel/RowLabel';
import Typography from '../components/Typography/Typography';
import data from '../data/cards';
import { minValue, maxValue, initialValue } from '../data/config';
import CardCreatorSchema from '../schema/CardCreatorSchema';
import OrderCreatorSchema from '../schema/OrderCreatorSchema';

const LoginPage: React.FC<PageProps> = () => {
  const [cards, setCards] = useState<CardType[]>(data);

  const [cardOrders, setCardOrders] = useState<CardOrder[]>([]);
  const [cardModal, setCardModal] = useState<CardType>(null);
  const [isLoading, setIsLoading] = useState(false);

  const cardCreator = useFormik<CardCreatorProps>({
    initialValues: {
      cardValue: initialValue,
      cardAmount: 1,
      cardType: null,
    },
    validationSchema: CardCreatorSchema,
    onSubmit: values => {
      const newOrders = [];

      for (let i = 0; i < values.cardAmount; i++) {
        newOrders.push({
          id: Date.now() + i,
          value: values.cardValue,
          type: { ...values.cardType },
        });
      }

      const newCardOrders = [...cardOrders, ...newOrders];

      cardCreator.resetForm();
      setCards([...data]);
      setCardOrders(newCardOrders);

      const giftCards: GiftCard[] = [];

      newCardOrders.forEach(cardOrder => {
        giftCards.push({ pattern: cardOrder.type.id, value: cardOrder.value });
      });

      orderCreator.setFieldValue('giftCards', giftCards);
    },
  });

  const orderCreator = useFormik<OrderCreatorProps>({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      company: '',
      nip: '',
      street: '',
      zipCode: '',
      city: '',
      business: false,
      newsletter: false,
      regulation: false,
      giftCards: [],
    },
    validationSchema: OrderCreatorSchema,
    onSubmit: async values => {
      setIsLoading(true);

      try {
        const mappedValues = {
          email: values.email,
          name: values.name,
          surname: values.surname,
          business_customer: values.business ? 1 : 0,
          regulation_acceptance: values.regulation ? 1 : 0,
          newsletter_acceptance: values.newsletter ? 1 : 0,
          giftCards: values.giftCards,
          nip: values.nip,
          street: values.street,
          city: values.city,
          zip_code: values.zipCode,
          company_name: values.company,
        };

        const response = await fetch('/api/create-order', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          mode: 'cors',
          method: 'post',
          body: formurlencoded(mappedValues),
        });

        const data = (await response.json()) as CardOrdererResponse;

        window.location.href = data.redirect_uri;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const orderValue = cardOrders.reduce((prev, curr) => {
    return prev + curr.value;
  }, 0);

  const businessDisabled = !orderCreator.values.business;

  const handleCardTypeClicked = (card: CardType) => {
    setCardModal(card);
  };

  const handleCardModalClose = () => {
    setCardModal(null);
  };

  const handleCardModalChoose = (card: CardType) => {
    setCardModal(null);
    cardCreator.setFieldValue('cardType', card);
    setCards(
      produce(cards, draft => {
        draft.forEach(loopCard => {
          if (loopCard.id === card.id) {
            loopCard.selected = true;
          } else {
            loopCard.selected = false;
          }
        });
      })
    );
  };

  const handleDeleteCard = (id: number): void => {
    setCardOrders(cardOrders.filter(order => order.id !== id));
  };

  return (
    <AppWrapper>
      <Container>
        <Hero />
        <Typography variant="h1" center mt={46}>
          ZAMÓW WIRTUALNĄ KARTĘ PODARUNKOWĄ DUKA
        </Typography>

        {/* <Typography variant="small" center mt={-11} mb={47}>
          (jeżeli chcesz zamówić fizyczną kartę podarunkową napisz do nas na adres <a href="mailto:giftcard@duka.com">giftcard@duka.com</a> - minimalna wartość
          zamówienia 500 zł)
        </Typography> */}
      </Container>

      <Container>
        <Background>
          <Typography variant="h2" center mt={18} mb={33}>
            STWÓRZ SWOJĄ KARTĘ PODARUNKOWĄ
          </Typography>

          <Flex>
            <Flex item width="23%">
              <RowLabel>WARTOŚĆ KARTY</RowLabel>
            </Flex>
            <Flex item>
              <PriceSlider
                min={minValue}
                max={maxValue}
                step={10}
                value={cardCreator.values.cardValue}
                onChange={price => cardCreator.setFieldValue('cardValue', price)}
              />
              <Typography variant="label" mt={7} mb={19} center>
                wybierz wartość
              </Typography>
            </Flex>
            <Flex item width="23%">
              <Input
                value={cardCreator.values.cardValue}
                type="number"
                name="cardValue"
                min={minValue}
                max={maxValue}
                onChange={cardCreator.handleChange}
                error={cardCreator.errors.cardValue && cardCreator.touched.cardValue}
              />
              <Typography variant="label" mt={7} mb={19} center>
                {cardCreator.errors.cardValue && cardCreator.touched.cardValue ? <Error>{cardCreator.errors.cardValue}</Error> : 'lub wpisz wartość'}
              </Typography>
            </Flex>
          </Flex>

          <Flex>
            <Flex item width="23%">
              <RowLabel>WYGLĄD KARTY</RowLabel>
            </Flex>
            <Flex item width="calc(77% - 5px)">
              <CardCarousel slides={cards} onClick={handleCardTypeClicked} />
              <CardModal currentCard={cardModal} cards={cards} label="Card Modal" onClose={handleCardModalClose} onChoose={handleCardModalChoose} />
              <Typography variant="label" mt={7} mb={26} center>
                {cardCreator.errors.cardType && cardCreator.touched.cardType ? <Error>{cardCreator.errors.cardType}</Error> : 'wybierz wygląd karty'}
              </Typography>
            </Flex>
          </Flex>

          <Flex mb={28}>
            <Flex item width="23%">
              <RowLabel>LICZBA KART</RowLabel>
            </Flex>
            <Flex item width="60px">
              <Input
                value={cardCreator.values.cardAmount}
                type="number"
                name="cardAmount"
                onChange={cardCreator.handleChange}
                error={cardCreator.errors.cardAmount && cardCreator.touched.cardAmount}
              />
              {cardCreator.errors.cardAmount && cardCreator.touched.cardAmount && (
                <Typography variant="label" mt={7} mb={26} center>
                  <Error>{cardCreator.errors.cardAmount}</Error>
                </Typography>
              )}
            </Flex>
          </Flex>

          <Flex mb={28}>
            <Flex item width="23%">
              <Button onClick={() => cardCreator.submitForm()}>DODAJ DO ZAMÓWIENIA</Button>
            </Flex>
          </Flex>

          <Flex>
            <Flex item width="23%">
              <RowLabel>ZAMÓWIENIE</RowLabel>
            </Flex>
            <Flex item>
              {cardOrders.map(order => {
                return (
                  <Flex key={order.id} mb={10}>
                    <Flex item>
                      <OrderName thumbUrl={order.type.image}>{order.type.name}</OrderName>
                    </Flex>
                    <Flex item width="10%">
                      <RowLabel>{order.value} PLN</RowLabel>
                    </Flex>
                    <Flex item width="10%">
                      <Button onClick={() => handleDeleteCard(order.id)}>Usuń</Button>
                    </Flex>
                  </Flex>
                );
              })}
              {cardOrders.length === 0 && !(orderCreator.errors.giftCards || orderCreator.touched.giftCards) && (
                <RowLabel>
                  <Typography variant="label" center>
                    brak wybranych kart
                  </Typography>
                </RowLabel>
              )}
              {cardOrders.length === 0 && orderCreator.errors.giftCards && orderCreator.touched.giftCards && (
                <RowLabel>
                  <Typography variant="label" center>
                    <Error>{orderCreator.errors.giftCards}</Error>
                  </Typography>
                </RowLabel>
              )}
            </Flex>
          </Flex>
          {!!orderValue && (
            <Flex mt={20}>
              <Flex item width="23%"></Flex>
              <Flex item>
                <Flex>
                  <Flex item></Flex>
                  <Flex item width="calc(20% + 5px)">
                    <OrderPrice>{orderValue} PLN</OrderPrice>
                    <Typography variant="label" mt={7} mb={26} center>
                      do zapłaty
                    </Typography>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Background>
      </Container>

      <Container>
        <Background>
          <Typography variant="h2" center mt={18} mb={33}>
            PODAJ DANE DO REALIZACJI ZAMÓWIENIA
          </Typography>

          <Flex>
            <Flex item width="calc(23% - ( 5px * 3/4 ))">
              <RowLabel>ZAMAWIAJĄCY</RowLabel>
            </Flex>
            <Flex item width="calc(25% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.name}
                type="text"
                name="name"
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.name && orderCreator.touched.name}
              />
              <Typography variant="label" mt={7} mb={19} center>
                {orderCreator.errors.name && orderCreator.touched.name ? <Error>{orderCreator.errors.name}</Error> : 'IMIĘ'}
              </Typography>
            </Flex>
            <Flex item width="calc(26% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.surname}
                type="text"
                name="surname"
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.surname && orderCreator.touched.surname}
              />
              <Typography variant="label" mt={7} mb={19} center>
                {orderCreator.errors.surname && orderCreator.touched.surname ? <Error>{orderCreator.errors.surname}</Error> : 'NAZWISKO'}
              </Typography>
            </Flex>
            <Flex item width="calc(26% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.email}
                type="text"
                name="email"
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.email && orderCreator.touched.email}
              />
              <Typography variant="label" mt={7} mb={19} center>
                {orderCreator.errors.email && orderCreator.touched.email ? (
                  <>
                    <Error>{orderCreator.errors.email}</Error> <HiInformationCircle data-tip data-for="email" />
                    <ReactTooltip id="email" type="dark" effect="solid">
                      <span>
                        Na podany adres zostanie wysłana
                        <br />
                        wirtualna karta podarunkowa
                        <br />
                        oraz dokumenty rozliczeniowe.
                      </span>
                    </ReactTooltip>
                  </>
                ) : (
                  <>
                    E-MAIL <HiInformationCircle data-tip data-for="email" />
                    <ReactTooltip id="email" type="dark" effect="solid">
                      <span>
                        Na podany adres zostanie wysłana
                        <br />
                        wirtualna karta podarunkowa
                        <br />
                        oraz dokumenty rozliczeniowe.
                      </span>
                    </ReactTooltip>
                  </>
                )}
              </Typography>
            </Flex>
          </Flex>

          <Flex>
            <Flex item width="calc(23% - ( 5px * 3/4 ))">
              <Checkbox checked={orderCreator.values.business} name="business" onChange={orderCreator.handleChange}>
                KLIENT BIZNESOWY <HiInformationCircle data-tip data-for="businessClient" />
                <ReactTooltip id="businessClient" type="dark" effect="solid">
                  <span>
                    Z uwagi na to że karta nie jest produktem fiskalnym,
                    <br />a jedynie przelewem środków, za zamówienie zostanie
                    <br />
                    wystawiona nota obciążeniowa.
                  </span>
                </ReactTooltip>
              </Checkbox>
            </Flex>
          </Flex>

          <Flex>
            <Flex item width="calc(23% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.company}
                type="text"
                name="company"
                disabled={businessDisabled}
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.company && orderCreator.touched.company}
              />
              <Typography variant={businessDisabled ? 'labelDisabled' : 'label'} mt={7} mb={19} center>
                {orderCreator.errors.company && orderCreator.touched.company ? <Error>{orderCreator.errors.company}</Error> : 'NAZWA FIRMY'}
              </Typography>
            </Flex>
            <Flex item width="calc(25% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.nip}
                type="text"
                name="nip"
                disabled={businessDisabled}
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.nip && orderCreator.touched.nip}
              />
              <Typography variant={businessDisabled ? 'labelDisabled' : 'label'} mt={7} mb={19} center>
                {orderCreator.errors.nip && orderCreator.touched.nip ? <Error>{orderCreator.errors.nip}</Error> : 'NIP'}
              </Typography>
            </Flex>
            <Flex item width="calc(26% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.street}
                type="text"
                name="street"
                disabled={businessDisabled}
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.street && orderCreator.touched.street}
              />
              <Typography variant={businessDisabled ? 'labelDisabled' : 'label'} mt={7} mb={19} center>
                {orderCreator.errors.street && orderCreator.touched.street ? <Error>{orderCreator.errors.street}</Error> : 'ULICA'}
              </Typography>
            </Flex>
            <Flex item width="calc(26% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.zipCode}
                type="text"
                name="zipCode"
                disabled={businessDisabled}
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.zipCode && orderCreator.touched.zipCode}
              />
              <Typography variant={businessDisabled ? 'labelDisabled' : 'label'} mt={7} mb={19} center>
                {orderCreator.errors.zipCode && orderCreator.touched.zipCode ? <Error>{orderCreator.errors.zipCode}</Error> : 'KOD POCZTOWY'}
              </Typography>
            </Flex>
            <Flex item width="calc(23% - ( 5px * 3/4 ))">
              <Input
                value={orderCreator.values.city}
                type="text"
                name="city"
                disabled={businessDisabled}
                onChange={orderCreator.handleChange}
                error={orderCreator.errors.city && orderCreator.touched.city}
              />
              <Typography variant={businessDisabled ? 'labelDisabled' : 'label'} mt={7} mb={19} center>
                {orderCreator.errors.city && orderCreator.touched.city ? <Error>{orderCreator.errors.city}</Error> : 'MIASTO'}
              </Typography>
            </Flex>
          </Flex>

          <Flex>
            <Flex item width="23%">
              <RowLabel>ZGODY FORMALNE</RowLabel>
            </Flex>
            <Flex item width="calc(77% - 5px)">
              <Typography variant="label" mb={10}>
                <Checkbox
                  checked={orderCreator.values.regulation}
                  background
                  name="regulation"
                  onChange={orderCreator.handleChange}
                  error={orderCreator.errors.regulation && orderCreator.touched.regulation}
                >
                  Zapoznałem/zapoznałam się z{' '}
                  <a href="https://duka.com/pl/regulamin-sklepu" target="_blank">
                    Regulaminem sklepu internetowego
                  </a>{' '}
                  oraz{' '}
                  <a href="https://duka.com/pl/polityka-prywatnosci" target="_blank">
                    Polityką prywatności
                  </a>{' '}
                  wraz z pouczeniem o prawie odstąpienia od umowy.*{' '}
                  {orderCreator.errors.regulation && orderCreator.touched.regulation && <Error>{orderCreator.errors.regulation}</Error>}
                </Checkbox>
              </Typography>
            </Flex>
          </Flex>

          <Flex mt={38} mb={28} center>
            <Flex item width="23%">
              <Button onClick={() => orderCreator.submitForm()} disabled={isLoading}>
                ZAMAWIAM I PŁACĘ{' '}
                {isLoading && <LoaderIcon style={{ width: '40px', height: '40px', marginTop: '-15px', marginBottom: '-15px', marginRight: '-44px' }} />}
              </Button>
            </Flex>
          </Flex>
          <Flex mb={28}>
            <Flex item width="100%">
              <Typography variant="disclaimer" center>
                (po naciśnięciu 'zamawiam i płacę' zostaniesz przeniesiony na stronę operatora płatności)
              </Typography>
            </Flex>
          </Flex>
        </Background>
      </Container>
    </AppWrapper>
  );
};

export default LoginPage;
