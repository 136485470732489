import { PageProps } from 'gatsby';
import * as React from 'react';
import AppWrapper from '../components/AppWrapper/AppWrapper';
import Container from '../components/Container/Container';
import Hero from '../components/Hero/Hero';
import Typography from '../components/Typography/Typography';

const Error404Page: React.FC<PageProps> = () => {
  return (
    <AppWrapper>
      <Container>
        <Hero />
        <Typography variant="h1" center mt={46} mb={78}>
          404 Taka strona nie istnieje
        </Typography>
      </Container>
    </AppWrapper>
  );
};

export default Error404Page;
