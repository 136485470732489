import cls from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import * as styles from './Button.module.scss';

const Button: React.FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({ variant = 'black', children, ...props }) => {
  return (
    <button {...props} className={cls(styles.button, styles[variant])}>
      {children}
    </button>
  );
};

export default Button;
