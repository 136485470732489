import image1url from '../images/ku_czarna.jpg';
import image2url from '../images/ku_czerwona.jpg';
import imageFull1url from '../images/ku_wzor_czarna.jpg';
import imageFull2url from '../images/ku_wzor_czerwona.jpg';

const data: CardType[] = [
  {
    id: '00',
    name: 'Karta podarunkowa czarna',
    image: image1url,
    imageFull: imageFull1url,
    selected: false,
  },
  {
    id: '01',
    name: 'Karta podarunkowa czerwona',
    image: image2url,
    imageFull: imageFull2url,
    selected: false,
  },
];

export default data;
