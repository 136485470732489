import cls from 'classnames';
import * as React from 'react';
import * as styles from './Typography.module.scss';
import tagMap from './tagMap';

const Typography: React.FC<TypographyProps> = ({ variant = 'h1', center, mt, mb, children }) => {
  const TagName = tagMap[variant] as keyof JSX.IntrinsicElements;
  const inlineStyles = {
    marginTop: mt && mt + 'px',
    marginBottom: mb && mb + 'px',
  };

  return (
    <TagName className={cls(styles[variant], { [styles.center]: center })} style={inlineStyles}>
      {children}
    </TagName>
  );
};

export default Typography;
