import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('podaj imię'),
  surname: Yup.string().required('podaj nazwisko'),
  email: Yup.string().email('podaj poprawny email').required('podaj email'),
  company: Yup.string().when('business', {
    is: true,
    then: Yup.string().required('podaj nazwę firmy'),
  }),
  nip: Yup.string().when('business', {
    is: true,
    then: Yup.string().required('podaj NIP'),
  }),
  street: Yup.string().when('business', {
    is: true,
    then: Yup.string().required('podaj ulicę'),
  }),
  zipCode: Yup.string().when('business', {
    is: true,
    then: Yup.string().required('podaj kod pocztowy'),
  }),
  city: Yup.string().when('business', {
    is: true,
    then: Yup.string().required('podaj miasto'),
  }),
  business: Yup.boolean(),
  newsletter: Yup.boolean(),
  regulation: Yup.boolean().isTrue('zgoda na warunki zawarte w regulaminie jest wymagana'),
  giftCards: Yup.array().min(1, 'wybierz przynajmniej jedną kartę'),
});

export default schema;
