import * as React from 'react';
import * as styles from './OrderName.module.scss';

const OrderName: React.FC<OrderNameProps> = ({ thumbUrl, children }) => {
  return (
    <div className={styles.orderName}>
      <img src={thumbUrl} />
      {children}
    </div>
  );
};

export default OrderName;
