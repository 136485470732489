import React from 'react';
import { RiArrowLeftSLine } from '@react-icons/all-files/ri/RiArrowLeftSLine';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';

const customStyles = {
  position: 'absolute',
  top: '50%',
  display: 'block',
  padding: 0,
  border: 0,
  outline: 0,
  fontSize: '120px',
  color: '#ffffff',
  cursor: 'pointer',
  transform: 'translate(0, -50%)',
  zIndex: 10,
};

export const CardCarouselModalNextArrow: React.FC = props => {
  const { className, style, styleOverride, onClick } = props;

  return (
    <div style={{ ...style, ...customStyles, ...styleOverride, right: 0 }} onClick={onClick}>
      <RiArrowRightSLine />
    </div>
  );
};

export const CardCarouselModalPrevArrow: React.FC = props => {
  const { className, style, styleOverride, onClick } = props;

  return (
    <div style={{ ...style, ...customStyles, ...styleOverride, left: 0 }} onClick={onClick}>
      <RiArrowLeftSLine />
    </div>
  );
};
