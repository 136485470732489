import { OrderPageProps } from '.';
import formurlencoded from 'form-urlencoded';
import React, { useEffect, useState } from 'react';
import AppWrapper from '../../components/AppWrapper/AppWrapper';
import Background from '../../components/Background/Background';
import Container from '../../components/Container/Container';
import Flex from '../../components/Flex/Flex';
import Hero from '../../components/Hero/Hero';
import OrderName from '../../components/OrderName/OrderName';
import OrderPrice from '../../components/OrderPrice/OrderPrice';
import RowLabel from '../../components/RowLabel/RowLabel';
import Typography from '../../components/Typography/Typography';
import cards from '../../data/cards';

const LoginPage: React.FC<OrderPageProps> = ({ id }) => {
  const [cardOrders, setCardOrders] = useState<CardOrder[]>([]);
  const [orderStatus, setOrderStatus] = useState('');
  const [orderError, setOrderError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await fetch('/api/get-order', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            //Authorization: 'Basic Z2lmdGNhcmQ6cXdlMTIz',
          },
          mode: 'cors',
          method: 'post',
          body: formurlencoded({ id }),
        });

        const data = (await response.json()) as CardDrderFromAPI;

        if (!data?.order?.id) {
          throw new Error('Błąd ładowania szczegółów zamówienia');
        }

        setCardOrders(
          data?.order?.giftCards?.map((giftCard, index) => {
            return {
              id: index,
              type: cards.find(searchCard => searchCard.id === giftCard.pattern),
              value: giftCard.value,
            };
          }) || []
        );

        setOrderStatus(data.order.orderStatus);
      } catch (e) {
        console.error(e);
        setOrderError('Takie zamówienie nie istnieje');
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  let orderStatusReadable = '';

  switch (orderStatus) {
    case 'NEW':
      orderStatusReadable = 'oczekuje na wpłatę';
      break;
    case 'PAYMENT_PENDING':
      orderStatusReadable = 'przetwarzanie płatności';
      break;
    case 'PAYMENT_CANCELED':
      orderStatusReadable = 'płatność anulowana';
      break;
    case 'PROCESSING':
      orderStatusReadable = 'przetwarzanie zamówienia';
      break;
    case 'COMPLETED':
      orderStatusReadable = 'zamówienie zrealizowane';
      break;
    default:
      orderStatusReadable = 'oczekiwanie na status zamówienia';
  }

  const orderValue = cardOrders.reduce((prev, curr) => {
    return prev + curr.value;
  }, 0);

  if (isLoading) {
    return (
      <AppWrapper>
        <Container>
          <Hero />
          <Typography variant="h1" center mt={46} mb={78}>
            Ładowanie szczegółów zamówienia
          </Typography>
        </Container>
      </AppWrapper>
    );
  }

  if (orderError) {
    return (
      <AppWrapper>
        <Container>
          <Hero />
          <Typography variant="h1" center mt={46} mb={78}>
            {orderError}
          </Typography>
        </Container>
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      <Container>
        <Hero />
        <Typography variant="h1" center mt={46} mb={78}>
          PODSUMOWANIE ZAMÓWIENIA
        </Typography>
      </Container>

      <Container>
        <Background>
          <Flex>
            <Flex item width="23%">
              <RowLabel>ZAMÓWIENIE</RowLabel>
            </Flex>
            <Flex item>
              {cardOrders.map(order => {
                return (
                  <Flex key={order.id} mb={10}>
                    <Flex item>
                      <OrderName thumbUrl={order.type.image}>{order.type.name}</OrderName>
                    </Flex>
                    <Flex item width="20%">
                      <RowLabel>{order.value}&nbsp;PLN</RowLabel>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          {!!orderValue && (
            <Flex mt={20}>
              <Flex item width="23%"></Flex>
              <Flex item>
                <Flex>
                  <Flex item></Flex>
                  <Flex item width="calc(20% + 5px)">
                    <OrderPrice>{orderValue}&nbsp;PLN</OrderPrice>
                    <Typography variant="label" mt={7} mb={26} center>
                      razem
                    </Typography>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex>
            <Flex item width="23%">
              <RowLabel>STATUS PŁATNOŚCI</RowLabel>
            </Flex>
            <Flex item>
              <Typography variant="label" mt={20} mb={20}>
                {orderStatusReadable}
              </Typography>
            </Flex>
          </Flex>
        </Background>
      </Container>
    </AppWrapper>
  );
};

export default LoginPage;
