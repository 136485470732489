import * as Yup from 'yup';
import { minValue, maxValue, minAmount, maxAmount } from '../data/config';

const schema = Yup.object().shape({
  cardValue: Yup.number()
    .typeError('kwota musi być liczbą')
    .min(minValue, `najmniejsza wartość karty to ${minValue}zł`)
    .max(maxValue, `największa wartość karty to ${maxValue}zł`)
    .required('podaj wartość karty'),
  cardAmount: Yup.number()
    .typeError('ilość kart wyraź liczbą')
    .min(minAmount, `minimalnie ${minAmount}`)
    .max(maxAmount, `maksymalnie ${maxAmount}`)
    .required('podaj ilość kart'),
  cardType: Yup.object().typeError('kliknij i wybierz wygląd karty').required('kliknij i wybierz wygląd karty'),
});

export default schema;
