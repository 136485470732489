import React from 'react';
import { Range } from 'react-range';
import * as styles from './PriceSlider.module.scss';

const PriceSlider: React.FC<PriceSliderProps> = ({ min, max, step, value, onChange }) => {
  return (
    <div className={styles.priceSlider}>
      <div className={styles.priceSliderLabel}>{min} PLN</div>
      <div className={styles.priceSliderSlider}>
        <Range
          step={step}
          min={min}
          max={max}
          values={[value]}
          onChange={([newValue]) => onChange(newValue)}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '3px',
                width: '100%',
                backgroundColor: '#000',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '16px',
                width: '16px',
                backgroundColor: '#000',
                borderRadius: '50%',
              }}
            />
          )}
        />
      </div>
      <div className={styles.priceSliderLabel}>{max} PLN</div>
    </div>
  );
};

export default PriceSlider;
