import cls from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { RiCheckboxBlankLine } from '@react-icons/all-files/ri/RiCheckboxBlankLine';
import { RiCheckboxLine } from '@react-icons/all-files/ri/RiCheckboxLine';
import * as styles from './Checkbox.module.scss';

const Checkbox: React.FC<CheckboxProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>> = ({ children, background = false, error = false, ...props }) => {
  return (
    <label className={cls(styles.checkbox, { [styles.background]: background, [styles.error]: error })}>
      {props.checked ? <RiCheckboxLine className={styles.icon} /> : <RiCheckboxBlankLine className={styles.icon} />}
      <input {...props} type="checkbox" className={styles.checkbox} />
      <span className={styles.text}>{children}</span>
    </label>
  );
};

export default Checkbox;
