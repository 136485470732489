import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from '@react-icons/all-files/ri/RiCloseLine';
import Button from '../Button/Button';
import CardsCarouselModal from '../CardsCarousel/CardsCarouselModal';
import * as style from './CardModal.module.scss';

Modal.setAppElement(`#___gatsby`);

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '100%',
    maxHeight: '100vh',
    margin: '0 auto',
    padding: 0,
    border: 0,
    background: 'transparent',
  },
};

const CardModal: React.FC<CardModalProps> = ({ label, currentCard, cards, onClose, onChoose }) => {
  const [currentSlide, setCurrentSlide] = useState<CardType>(null);
  const [initialSlide, setInitialSlide] = useState<number>(0);

  useEffect(() => {
    setCurrentSlide(currentCard);
    setInitialSlide(currentCard ? cards.findIndex(searchCard => searchCard.id === currentCard.id) : 0);
  }, [currentCard]);

  const handleChange = (newCurrentSlide: CardType) => {
    setCurrentSlide(newCurrentSlide);
  };

  return (
    <Modal isOpen={!!currentCard} onRequestClose={onClose} style={customStyles} contentLabel={label}>
      <div className={style.modalClose}>
        <RiCloseLine onClick={onClose} />
      </div>

      <CardsCarouselModal slides={cards} initialSlide={initialSlide} onChange={handleChange} onClick={() => onChoose(currentSlide)} />

      <div className={style.modalButton}>
        <Button onClick={() => onChoose(currentSlide)}>WYBIERZ KARTĘ</Button>
      </div>
    </Modal>
  );
};

export default CardModal;
