// extracted by mini-css-extract-plugin
export var h1 = "Typography-module--h1--1tybV";
export var h2 = "Typography-module--h2--317l_";
export var h3 = "Typography-module--h3--1z3ni";
export var h4 = "Typography-module--h4--20NOS";
export var h5 = "Typography-module--h5--6yvp7";
export var h6 = "Typography-module--h6--3uKU6";
export var label = "Typography-module--label--1AmSH";
export var labelDisabled = "Typography-module--labelDisabled--2GeG-";
export var disclaimer = "Typography-module--disclaimer--m-_Zq";
export var small = "Typography-module--small--1aCml";
export var div = "Typography-module--div--2ZI39";
export var center = "Typography-module--center--3ZTke";