import cls from 'classnames';
import React from 'react';
import Slider from 'react-slick';
import { RiCheckboxCircleLine } from '@react-icons/all-files/ri/RiCheckboxCircleLine';
import * as styles from './CardsCarousel.module.scss';
import { CardCarouselModalNextArrow, CardCarouselModalPrevArrow } from './CardsCarouselModalArrows';
import './slick-theme.scss';
import './slick.scss';

const CardsCarousel: React.FC<CardsCarouselProps> = ({ slides, onClick }) => {
  const handleClick = (item: CardType) => {
    if (typeof onClick === 'function') {
      onClick(item);
    }
  };

  const settings = {
    dots: false,
    arrows: true,
    nextArrow: <CardCarouselModalNextArrow styleOverride={{ color: 'black' }} />,
    prevArrow: <CardCarouselModalPrevArrow styleOverride={{ color: 'black' }} />,
    infinite: slides.length > 3,
    speed: 500,
    slidesToShow: Math.min(6, slides.length),
    slidesToScroll: 1,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: Math.min(4, slides.length),
      //     slidesToScroll: 1,
      //   },
      // },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, slides.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: Math.min(2, slides.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(1, slides.length),
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.cardsCarousel}>
      <Slider {...settings}>
        {slides.map(item => {
          return (
            <div
              key={item.id}
              className={cls(styles.cardsCarouselSlide, { [styles.cardsCarouselSlideSelected]: item.selected })}
              onClick={() => handleClick(item)}
            >
              <img src={item.image} />
              {item.selected && (
                <div className={styles.cardsCarouselSelected}>
                  <RiCheckboxCircleLine />
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CardsCarousel;
